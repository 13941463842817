//
// Boxes
// --------------------------------------------------
.box-counter {
	text-align: center;
	color: $black;
}

.box-counter__icon {
	font-size: 44px;
	line-height: 1;
	color: $primary;
}

.box-counter__title {
	font-family: $font-family-sec;
	font-size: 14px;
	font-weight: 400;
	line-height: 1.3;
	letter-spacing: .06em;
	text-transform: uppercase;
	color: $gray-dark;
}

.box-counter__wrap {
	> * {
		font-family: $font-family-sec;
		display: inline;
		font-size: 40px;
		font-weight: 700;
		line-height: 1.2;
	}
} 

* + .box-counter__title { 
	margin-top: 10px; 
}

* + .box-counter__wrap {
	margin-top: 15px;
}

@include media-breakpoint-up(xl) {
	.box-counter__title {
		font-size: 16px; 
	} 
} 

.box-icon-image {
	transition: 280ms ease-out; 

	&:hover {
		transform: translateY(-3px);
		filter: drop-shadow(-8px 8px 2px rgba($black, .2)); 
	}
} 