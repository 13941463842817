//
// Comments
// --------------------------------------------------

// Comment minimal
.comment-minimal {
  > * + * {
    margin-top: 5px;
  }
}

.comment-minimal__author {
  font-size: 14px;
  font-style: italic;
  color: $body-color;
}

.comment-minimal__link {
  font-size: 16px;
  line-height: (26 / 16);
  color: $black;

  a {
    display: inline;
    color: inherit;

    &:hover {
      color: $primary;
    }
  }
}

// Box comment post
.box-comment {
  * + & { margin-top: 32px; }
  & + & {
    margin-top: 40px;
  }

  img {
    max-width: 70px;
  }

  * + time,
  p + * { margin-top: 8px; }
  * + p { margin-top: 0; }

  p {
    @include small;
  }

  time,
  a {
    font-size: 14px;
    font-style: italic;
  }

  time { color: $black; }
  img { width: auto; }
  .unit__body {
    > * {
      width: 100%;
    }
  }

  * + p {
    margin-top: 10px;
  }
}

.box-comment__header {
  @include media-breakpoint-up(sm) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-left: -10px;
    margin-right: -10px;
    
    > * {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}

.box-comment .box-comment__title {
  font-size: 16px;
  line-height: (26 / 16); 
  color: $headings-color;
  
  @include media-breakpoint-up(lg) {
    font-size: $font-size-base;
    line-height: $line-height-base;
  }
}

.box-comment.box-comment-reply {
  padding-left: 20px;

  * + & {
    margin-top: 20px;
  }

  @include media-breakpoint-up(sm) {
    padding-left: 40px;
  }
  @include media-breakpoint-up(md) {
    padding-left: 100px;
  }
}

.box-comment__icon {
  color: $gray-1;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  line-height: 70px;
  text-align: center;
  border: 1px solid;

  .icon { 
    font-size: 27px;
    line-height: 70px;
    &::before {
      line-height: inherit;
    }
  }

  @include media-breakpoint-up(md) {
  }
}

.box-comment__body {
  position: relative;
  border-radius: $border-radius-sm;
  background-color: $gray-lighter;
  padding: 15px;

  &:before {
    bottom: 100%;
    left: 35px;
    border: 0 solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(136, 183, 213, 0);
    border-bottom-color: $gray-lighter;
    border-width: 11px;
    margin-left: -11px;
  }

  @include media-breakpoint-up(sm) {
    &:before {
      right: 100%;
      top: 35px;
      bottom: auto;
      left: auto;
      border-right-color: $gray-lighter;
      border-bottom-color: transparent;
      margin-top: -11px;
      margin-left: 0;
    }
  }

  @include media-breakpoint-up(md) {
    padding: 30px 35px;
  }
}

// product comment

.box-comment__product {
  position: relative;
  .unit-body {
    width: 100%;
  }
  .rating-list {

    .icon {
      font-size: 12px;
    }

    @include media-breakpoint-up(md) {
      position: absolute;
      top: 25px;
      right: 30px;
    }
  }
}