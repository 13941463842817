//
// Parallax header
// --------------------------------------------------
.parallax-header__inner {
  position: relative;
  text-align: center;
}

.parallax-header__inner {
  position: relative;
  z-index: 2;
}

.parallax-header__content {
  width: 100%;
  padding: 50px 0;
}

@include media-breakpoint-up(md) {
  .parallax-header__inner {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    //min-height: 33.3333vh;
  }

  .ie-10,
  .ie-11 {
    .parallax-header__inner {
      display: block;
      vertical-align: middle;
      white-space: nowrap;
      text-align: center;

      > * {
        display: inline-block;
        width: 100%;
        vertical-align: middle;
        white-space: normal;
      }

      &:after {
        content: '';
        display: inline-block;
        vertical-align: middle;
        min-height: inherit;
        width: 0;
      }
    }
  }

  .parallax-header__content {
    padding: 80px 0;
  }
}
