//
// Icons
// --------------------------------------------------

// Base styles
// --------------------------------------------------

.icon {
  display: inline-block;
  line-height: 1;
  text-align: center;

  &:before {
    display: inline-block;
    font-weight: 400;
    font-style: normal;
    speak: none;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

// Alternate icons
// --------------------------------------------------
.page {
  .icon-default {
    color: $body-color;
  }

  .icon-gray {
    color: $gray;
  }

  .icon-primary {
    color: $primary;
    
    &.icon-circle {
      background-color: $primary;
      color: $white;  
    }
  }

  .icon-gray-4 {
    color: $gray-4;
  }

  a {
    &.icon-default {
      &:hover { color: $secondary; }
    }

    &.icon-primary {
      color: $primary;

      &:hover { color: $black; }
    }
    
    &.icon-gray-darker {
      color: $gray-darker;
      
      &:hover {
        color: $primary;
      }
    }
  }
  
  
}

// Button Sizes
// --------------------------------------------------
.page {
  .icon-xxs {
    font-size: 15px;
  }

  .icon-sm {
    font-size: 20px;
    line-height: 20px;
  }

  .icon-md {
    font-size: 30px;
    line-height: 30px;
  }

  .icon-lg {
    font-size: 44px;
  }

  .icon-xxl {
    font-size: 48px;

    @include media-breakpoint-up(lg) {
      font-size: 80px;
    }
  }
}

// Icon shapes
// --------------------------------------------------
.icon-circle {
  border-radius: 100%;
}

.icon.icon-circle {
  width: 2.2em;
  height: 2.2em;
  line-height: 2.3em;
}

.icon.icon-round {
  border-radius: 4px;
  width: 2.2em;
  height: 2.2em;
  line-height: 2.2em;
}

.icon-list-wrap {
  > *,
  &:before {
    vertical-align: middle;
    margin-right: 6px;
  }
}

.linear-icon-map-marker {
  &:before{
    position: relative;
    left: -3px;
  }
}
