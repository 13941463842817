//
// Thumb
// --------------------------------------------------

.thumb-default {
  img {
    width: 100%;
    height: auto;
  }

  figcaption {
    color: $gray;
  }

  * + figcaption {
    margin-top: 10px;
  }
}

figure {
  img {
    display: block;
    width: 100%;
  }
}

.figure-inline {
  img {
    width: auto;
  }
}

.figure-centered {
  text-align: center;
  img {
    display: inline-block;
    width: inherit;
  }
}

.image-centered {
  width: min-content;
}

// Thumb
.thumb {
  text-align: center;
}

.thumb__title {
  font-size: 20px;
  line-height: 1.33;
  color: $black;

  a {
    color: inherit;

    &:hover {
      color: $primary;
    }
  }
}

.thumb__subtitle {
  @include small;
  font-style: italic;
}

* + .thumb__subtitle {
  margin-top: 5px;
}

@include media-breakpoint-up(xl) {
  .thumb__title {
    font-size: 24px;
    line-height: 1.3;
  }
}

// Thumb minimal
.thumb-minimal {
  position: relative;
  will-change: transform;
  transform: translate3d(0, 0, 0);
  width: 100%;
  padding-bottom: 100%;
  border-radius: 3px;

  a {
    display: block;
  }

  figure {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: 100%;
    height: auto;
    will-change: transform;
    border-radius: 3px;
  }

  &:hover {
    .thumb-minimal__overlay {
      opacity: 1;

      &::before {
        transform: scale(1);
      }
    }
  }
}

.thumb-minimal__overlay {
  position: absolute;
  top: -1px;
  right: 0;
  bottom: 0;
  left: -1px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background: rgba($primary, .9);
  border-radius: 3px;
  opacity: 0;
  transition: all .4s ease-out;
  will-change: opacity, transform;

  .ipad & {
    bottom: -1px;
  }

  &::before {
    position: relative;
    display: block;
    content: '\e936';
    font-family: 'Linearicons';
    font-size: 24px;
    color: $white;
    transform: scale(0);
    transition: all .4s ease-out;
  }
}

.thumb-minimal.thumb-minimal_lg {
  .thumb-minimal__overlay {
    &::before {
      font-size: 50px;
    }
  }
}

// Thumb Corporate
.thumb-corporate {
  @include responsive-block;

  img {
    width: 100%;
    height: auto;
  }

  * + .thumb-corporate__list {
    margin-top: 15px;
  }
}

.thumb-corporate__overlay {
  padding: 20px 10px 5px;
}

* + .thumb-corporate__caption {
  margin-top: 10px;
}

@include media-breakpoint-up(lg) {
  .desktop {
    .thumb-corporate {
      &:hover {
        .thumb-corporate__overlay {
          opacity: 1;

          .thumb-corporate__list {
            > li {
              opacity: 1;
              transform: matrix(1, 0, 0, 1, 0, 0);
              transition-delay: .1s;
            }
          }
        }
      }
    }

    .thumb-corporate__main {
      position: relative;
    }

    .thumb-corporate__overlay {
      position: absolute;
      top: 0;
      right: 0;
      bottom: -2px;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
      transition: opacity .2s ease-in-out;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba($gray-lighter, .9);
        transition: transform .3s ease-in-out;
      }

      .thumb-corporate__list {
        > li {
          position: relative;
          opacity: 0;
          transition: transform .3s ease-in-out, opacity .1s;
          transform: matrix(.01, 0, 0, 1, 0, 0);
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  * + .thumb-corporate__caption {
    margin-top: 25px;
  }
}

// Thumb Flat
.thumb-flat {
  text-align: center;
  max-width: 418px;
  margin-left: auto;
  margin-right: auto;

  * + .thumb-flat__subtitle {
    margin-top: 5px;
  }
}

.thumb-flat__subtitle {
  font-style: italic;
  color: $primary;
  font-size: 14px;
  line-height: 1.3;
}

* + .thumb-flat__body {
  margin-top: 20px;
}

@include media-breakpoint-up(md) {
  .thumb-flat__subtitle {
    font-size: 16px;
  }

  * + .thumb-flat__body {
    margin-top: 30px;
  }
}

@include media-breakpoint-up(lg) {
  @include media-breakpoint-down(lg) {
    .thumb-flat__body {
      font-size: 16px;
    }
  }
}

// Thumb creative
.thumb-creative {
  position: relative;
  text-align: center;

  @include media-breakpoint-down(sm) {
    max-width: 370px;
    margin-left: auto;
    margin-right: auto;
  }
}

.thumb-creative,
.thumb-creative__inner {
  display: flex;
  width: 100%;
}

.thumb-creative__image-wrap {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
}

.thumb-creative__image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 101%;
  min-height: 100%;
  max-width: none;
  width: auto;
}

.thumb-creative__front,
.thumb-creative__back {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: $gray-dark;
}

.thumb-creative__front {
  display: none;
}

.thumb-creative__back {
  position: relative;
  overflow: hidden;

  img {
    opacity: .35;
  }
}

.thumb-creative__content {
  position: relative;
  overflow: hidden;
  z-index: 1; 
  display: flex;
  flex-direction: column;
  align-items: center; 
  justify-content: center;
  width: 100%;
  padding: 50px 40px;
  color: $white;
  pointer-events: none;

  > * {
    width: 100%;
    pointer-events: auto;
  }

  &::before {
    content: '';
  }

  #{headings()} {
    line-height: 1.4;
    color: $white;

    a {
      color: inherit;

      &:hover {
        color: $primary;
      }
    }
  }

  a {
    color: $primary;

    &:hover { color: $white; }
  }

  a.button-link {
    color: $white;

    &:hover { color: $primary; }
  }
}

* + .thumb-creative__title {
  margin-top: 15px;
}

@include media-breakpoint-between(sm, sm) {
  .thumb-creative__content {
    padding: 30px 10px;
    
    .button-link {
      font-size: 12px;
    }
  }
}

@include media-breakpoint-up(md) {
  * + .thumb-creative__title {
    margin-top: 22px;
  }
}

@include media-breakpoint-up(lg) {
  .thumb-creative__content {
    min-height: 300px;
  }

  .ie-10,
  .ie-11 {
    .thumb-creative__back .thumb-creative__content {
      display: table-cell;
      vertical-align: middle;
    }
  }

  @include media-breakpoint-down(lg) {
    .thumb-creative__content {
      padding: 25px 30px;
      font-size: 14px;
      line-height: 1.5;
    }
  }
}

.desktop {
  @include media-breakpoint-up(lg) {
    .thumb-creative {
      &:hover {
        .thumb-creative__front {
          transform: rotateY(-180deg);
        }

        .thumb-creative__back {
          transform: rotateY(0deg);
        }
      }
    }

    .thumb-creative__content {
      transform: translateZ(60px);
      transition: all 0.4s ease-out;
    }

    .thumb-creative__inner {
      position: relative;
      -webkit-transform-style: preserve-3d;
      transform-style: preserve-3d;
      -webkit-perspective: 1000px;
      perspective: 1000px;
    }

    .thumb-creative__front,
    .thumb-creative__back {
      overflow: visible;
      -webkit-transform-style: preserve-3d;
      transform-style: preserve-3d;
      transition: -webkit-transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
      transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1), -webkit-transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
      backface-visibility: hidden;
      -webkit-backface-visibility: hidden;
    }

    .thumb-creative__front {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      transform: rotateY(0deg);
      z-index: 1;

      .thumb-creative__image {
        opacity: .35;
      }

      .thumb-creative__content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;

        > * {
          opacity: 1;
        }
      }
    }

    .thumb-creative_no-cover .thumb-creative__front {
      .thumb-creative__image {
        opacity: 1;
      }
    }

    .thumb-creative__back {
      transform: rotateY(180deg);
      opacity: 1;

      .thumb-creative__image {
        opacity: .05;
      }
    }
  }
}

@include media-breakpoint-between(xl, xl) {
  .thumb-creative__content {
    min-height: 270px;
  }

  .thumb-creative__back {
    .thumb-creative__content {
      p {
        display: none;
      }

      a.button-link {
        font-size: 12px;
      }
    }
  }
}

@include media-breakpoint-up(xxl) {
  .thumb-creative .thumb-creative__content {
    min-height: 360px;
  }
}

//thumbnail classic
//

.thumbnail-classic {
  max-width: 420px;
  margin-left: auto;
  margin-right: auto;
  text-align: left;

  img {
    transition: 370ms ease-in-out;
  }
  > a {
    width: 100%;
  }

  .caption {
    margin-top: 25px;
  }

  &:hover {
    img {
      box-shadow: $shadow-area-lg;
    }
  }
}

a.thumbnail-classic-title {
  &:hover {
    color: $primary;
  }
}

.thumbnail-classic-time {
  display: inline-block;
  font-size: 12px;
  font-style: italic;
  color: $primary;

  * + & {
    margin-top: 15px;
  }
}

//thumbnail classic minimal
//

.thumbnail-classic-minimal {
  text-align: center;

  img {
    width: auto;
  }

  .caption {
    @include small;
    text-align: left;
  }

  * + .caption {
    margin-top: 28px;
  }
}

// Img thumbnail
.img-thumbnail-variant-1 {
  display: block;
  position: relative;

  .caption {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba($gray-darker, .5);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transform: scale(.8);
    transition: 350ms ease-in-out;
    color: $white;
  }

  &:hover {
    .caption {
      opacity: 1;
      transform: scale(1);
    }
  }
}

.img-thumbnail-variant-2 {
  position: relative;
  overflow: hidden;
  .caption {
    position: absolute;
    top: 20px;
    right: 20px;
    opacity: 0;
    transform: scale(.8) translateX(100%);
    transition: 350ms ease-in-out;
  }
  .icon-lg {
    font-size: 30px;
  }
  &:hover {
    .caption {
      opacity: 1;
      transform: scale(1) translateX(0);
    }
  }
}

.img-thumbnail-variant-3 {
  position: relative;
  display: block;
  text-align: center;
  overflow: hidden;
  cursor: pointer;
  
  img{
    width: 100%;
  }

  .icon { font-size: 30px; }

  .arrow-right { font-size: 30px; }

  .list-inline-tag {
    font-size: 12px;
    font-style: italic;
  }

  .label-custom {
    position: absolute;
    z-index: 11;
    top: 18px;
    left: 18px;
    padding: 2px 7px;
    font-size: 12px;
    font-style: italic;
    background-color: $white;
    border-radius: $border-radius-sm;
    color: $black;
  }

  .caption {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 15px;
    background-color: rgba($primary, .6);
    transition: 250ms ease-in-out;

    > * { color: $white; }
    > * + * {
      margin-top: 5px;
    }
    .divider {
      position: relative;
      margin-top: 8px;
      margin-bottom: 8px;
      max-width: 116px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  @include media-breakpoint-up(xl) {
    .icon { font-size: 40px; }
    .caption {
      padding: 50px 55px 45px;
      > * + * {
        margin-top: 10px;
      }
      .divider {
        margin-top: 19px;
        margin-bottom: 19px;
      }
    }
    .arrow-right {
      position: absolute;
      right: 18px;
      bottom: 13px;
    }
  }

  .desktop & {
    .caption {
      opacity: 0;
      transform: scale(.8);
    }

    .divider {
      background: transparent;
      &:before {
        position: absolute;
        content: "";
        display: inline-block;
        left: 50%;
        right: 50%;
        height: 1px;
        background-color: $white;
        transition: 250ms 280ms ease-in-out;
      }
    }

    .hover-top-element {
      opacity: 0;
      transform: translateY(30px);
      transition: 250ms 320ms linear;
    }

    .hover-bottom-element {
      opacity: 0;
      transform: translateY(-40px);
      transition: 250ms 320ms linear;
    }

    &:hover {
      .caption {
        opacity: 1;
        transform: scale(1);
      }
      
      

      .hover-top-element,
      .hover-bottom-element {
        opacity: 1;
        transform: translateY(0);
      }
      .divider {
        &:before {
          left: 0;
          right: 0;
        }
      }

    }
  }
}

@keyframes hover-blur {
  100%{
    filter: blur(3px);
  }
}

.thumbnail-type-2{
  position: relative;
  display: inline-block;

  .label { 
    top: -10px;
    right: 10px;
    position: absolute;
    z-index: 1;
    box-shadow: 1px 4px 15px 0 rgba(0, 0, 0, 0.35);;
  }

  @include media-breakpoint-up(sm) {
    .label {
      right: 30px;
    }
  }

  figure{
    transition: box-shadow 0.3s ease;
    box-shadow: 10px 10px 74px -15px rgba(0, 0, 0, 0.1);
    //filter: brightness(95%); 
    img{
      transform: scale(1);
      transition: .4s all ease-in-out;
    }

    &:hover{
      box-shadow: 10px 10px 74px -15px rgba(0, 0, 0, 0.3);
      img{
        transform: scale(1.03);
      }
    }
  } 

  .caption{
    margin-top: 20px;

    &-title{
      color: $black;
      font-size: 18px;
      line-height: 1.3;
      text-transform: capitalize;
      &:hover {
        color: $primary;
      }
    }
  }
}



// Thumb modern
.thumb-modern {
  @include responsive-block;
  position: relative;
  overflow: hidden;
  display: block;
  width: 100%;
  margin-top: -1px;
  padding-bottom: 75.21%;
  transform: translate3d(0, 0, 0);

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    will-change: transform;
    transform: translate3d(-50%, -50%, 0);
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    max-width: none;
  }

  &:hover {
    .thumb-modern__overlay {
      opacity: 1;

      &::before {
        transform: scale(1);
      }
    }
  }
}

.thumb-modern__overlay {
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  @include display-flex;
  @include flex-direction(row);
  @include flex-wrap(wrap);
  @include align-items(center);
  @include justify-content(center);
  background: rgba($primary, .9);
  opacity: 0;
  transition: all .4s ease-out;
  will-change: opacity, transform;

  &::before {
    position: relative;
    display: block;
    content: '\e936';
    font-family: 'Linearicons';
    font-size: 30px;
    color: $white;
    transform: scale(0);
    will-change: transform;
    transition: all .4s ease-out;
  }

  @include media-breakpoint-up(md) {
    &::before {
      font-size: 50px;
    }
  }
}

.thumb-flat.thumb-flat-modern {
  .thumb-flat__body {
    background: $white;
    padding: 30px;
    * + .button {
      margin-top: 25px;
    }
    @include media-breakpoint-up(md) {
      padding: 35px 40px;
    }
  }
  * + .thumb-flat__body {
    margin-top: 0;
    @include media-breakpoint-up(md) {
      margin-top: 0;
    }
  }
}

.thumb-product {
  max-width: 420px;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  * + [class*=list-inline-] {
    margin-top: 0;
  }
}
.thumb-product-img-block {
  position: relative;
  overflow: hidden;
  min-height: 270px;
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1);
    transition: all .3s;
    will-change: transform;
    height: inherit;
    @include media-breakpoint-down(xl) {
      max-width: inherit; 
    }
  }
}
.thumb-product-meta {
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $white;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  bottom:0;
  left: 0;
  right: 0;
  padding: 20px;
  background: linear-gradient(to top, rgba($black, .85), rgba($black, .5) 55%, transparent);
  z-index: 2;
  @include media-breakpoint-down(md) {
    padding: 20px 10px;
  }
  .product-price {
    color: $primary;
    display: inline-block;
    margin-left: 4px;
  }
  .icon {
    color: $primary;
    margin-right: 4px;
  }
}
.thumb-product-caption {
  padding: 30px;
  border: 1px solid $gray-light;
  border-top: none;
  background: $white;
  transition: all .3s;
  .thumb-product-header {
    padding-bottom: 10px;
    border-bottom: 1px solid $gray-light;
    h5 + p {
      margin-top: 5px;
    }
  }
  
}
.thumb-product-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  a {
    text-transform: uppercase;
    font-weight: 500;
  }
  .icon {
    color: $primary;
  }
}

.thumb-product:hover {
  .thumb-product-img-block {
    img {
      transform: translate(-50%, -50%) scale(1.02);
    }
  }
  .thumb-product-caption {
    box-shadow: $shadow-area-lg;
  }
}


// thumbnail-modern
.thumbnail-modern {
  position: relative;
  overflow: hidden;
  min-height: 320px;
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1);
    transition: all .3s;
    will-change: transform;
    height: inherit;
    max-width: inherit;
    @include media-breakpoint-only(sm) {
      width: 100%;
    }
  }
  .caption {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px;
    z-index: 1;
    background: linear-gradient(to top, rgba($black, .85), rgba($black, .5) 55%, transparent);
    .thumbnail-title {
      color: $white;
      &:hover {
        color: $primary;
      }
    }
  }
  &:hover {
    img {
      transform: translate(-50%, -50%) scale(1.02);
    }
  }
}