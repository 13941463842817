/*
* @subsection Google Map
* @description Describes style declarations for RD Google Map extension
* @version     1.0.0
*/
$map-height: 200px;
$map-xs-height: 300px;
$map-lg-height: 640px;

.google-map-markers{
  display: none;
}

.google-map-container {
  width: 100%;
}
.google-map {
  height: $map-height;
  width: 100%;

  @include media-breakpoint-up(sm) {
    height: $map-xs-height;
  }

  @include media-breakpoint-up(xl) {
    height: $map-lg-height;
  }
}

.gm-style-iw > div > div {
  padding-right: 10px;
  font-size: 13px;
}
